import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const BathIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-2 -1 16 16"className={className} onClick={onClick}>
      <path d="M3.66671 5.00004C4.40309 5.00004 5.00004 4.40309 5.00004 3.66671C5.00004 2.93033 4.40309 2.33337 3.66671 2.33337C2.93033 2.33337 2.33337 2.93033 2.33337 3.66671C2.33337 4.40309 2.93033 5.00004 3.66671 5.00004Z" fill="#0C0F24"/>
      <path d="M12.3334 7.66671V2.22004C12.3334 1.18004 11.4867 0.333374 10.4467 0.333374C9.94671 0.333374 9.46671 0.533374 9.11337 0.886707L8.28004 1.72004C8.17337 1.68671 8.06004 1.66671 7.94004 1.66671C7.67337 1.66671 7.42671 1.74671 7.22004 1.88004L9.06004 3.72004C9.19337 3.51337 9.27337 3.26671 9.27337 3.00004C9.27337 2.88004 9.25337 2.77337 9.22671 2.66004L10.06 1.82671C10.16 1.72671 10.3 1.66671 10.4467 1.66671C10.7534 1.66671 11 1.91337 11 2.22004V7.66671H6.43337C6.23337 7.52671 6.05337 7.36671 5.88671 7.18671L4.95337 6.15337C4.82671 6.01337 4.66671 5.90004 4.49337 5.82004C4.28671 5.72004 4.06004 5.66671 3.82671 5.66671C3.00004 5.67337 2.33337 6.34004 2.33337 7.16671V7.66671H0.333374V11.6667C0.333374 12.4 0.933374 13 1.66671 13C1.66671 13.3667 1.96671 13.6667 2.33337 13.6667H11.6667C12.0334 13.6667 12.3334 13.3667 12.3334 13C13.0667 13 13.6667 12.4 13.6667 11.6667V7.66671H12.3334ZM12.3334 11.6667H1.66671V9.00004H12.3334V11.6667Z" fill="#0C0F24"/>
    </svg>
  );
};

export default BathIcon;
