import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const BedIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="1 -2.5 10 13" className={className} onClick={onClick}>
      <path d="M13.6666 5.00004C13.6666 4.26671 13.0666 3.66671 12.3333 3.66671V1.66671C12.3333 0.933374 11.7333 0.333374 10.9999 0.333374H2.99992C2.26659 0.333374 1.66659 0.933374 1.66659 1.66671V3.66671C0.933252 3.66671 0.333252 4.26671 0.333252 5.00004V8.33337H1.21992L1.66659 9.66671H2.33325L2.77992 8.33337H11.2266L11.6666 9.66671H12.3333L12.7799 8.33337H13.6666V5.00004ZM10.9999 3.66671H7.66659V1.66671H10.9999V3.66671ZM2.99992 1.66671H6.33325V3.66671H2.99992V1.66671ZM1.66659 5.00004H12.3333V7.00004H1.66659V5.00004Z" fill="#0C0F24"/>
    </svg>
  );
};

export default BedIcon;
