import React from 'react';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import { ThemeNames } from 'types/themes';
import { useThemeContext } from 'contexts';

interface Props {
  isSmallCard?: boolean;
  isImageReady: boolean;
}

export default function ListingCardNewImageOverlay({ isSmallCard, isImageReady }: Props) {
  const { themeName } = useThemeContext();
  const isZoocasaTenant = themeName === ThemeNames.ZOOCASA;

  return (
    <div className={buildClassName(styles.component, !isImageReady && styles.black)}>
      <p className={buildClassName(styles['new-overlay-text'], isSmallCard && styles['small-text'], isZoocasaTenant && styles['zoocasa-theme'])}>
        Sign In Required
      </p>
    </div>
  );
}