import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const SqftIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 -1 17 16" className={className} onClick={onClick}>
      <g clipPath="url(#clip0_2775_284)">
        <path d="M2.96875 14H4.30208V12.6667H2.96875V14ZM5.63542 14H6.96875V12.6667H5.63542V14ZM4.30208 4.66667H2.96875V6H4.30208V4.66667ZM2.96875 11.3333H4.30208V10H2.96875V11.3333ZM6.96875 2H5.63542V3.33333H6.96875V2ZM4.30208 2H2.96875V3.33333H4.30208V2ZM12.3021 2H10.9688V3.33333H12.3021V2ZM13.6354 6H14.9688V4.66667H13.6354V6ZM13.6354 2V3.33333H14.9688V2H13.6354ZM10.9688 14H12.3021V12.6667H10.9688V14ZM9.63542 2H8.30208V7.33333H2.96875V8.66667H8.30208V14H9.63542V8.66667H14.9688V7.33333H9.63542V2ZM13.6354 14H14.9688V12.6667H13.6354V14ZM13.6354 11.3333H14.9688V10H13.6354V11.3333Z" fill="#0C0F24"/>
      </g>
      <defs>
        <clipPath id="clip0_2775_284">
          <rect width="16" height="16" fill="white" transform="translate(0.96875)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SqftIcon;
